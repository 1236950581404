$success: #02c55d;
$warning: #fcda00;

@import '~bootstrap/scss/bootstrap';
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

body {
    font-family: 'Roboto', sans-serif;
    color: #303036;
}
.thumb img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  max-height: 520px;
}
.modal-footer {
    justify-content: center;
}
textarea#warning {
    min-height: 250px;
}
.thumb-container {
    border: 1px solid #dee2e6;
}
.thumb-logo {
    background: #f9f9f9;
    border-bottom: 1px solid #dee2e6;
}
.btn {
    border-radius: 0;
    font-weight: bold;
}
.title {
    font-size: 1.2rem;
}
.models {
    font-size: 1rem;
}
.breaker {
    border-bottom: 1px solid #dee2e6;
}
.meta-data {
    font-size: 0.9rem;
}
@media (min-width: 720px) {
    .description {
        font-size: 0.9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        line-height: 1.3rem;
        max-height: 5.2rem;     
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
}
.description {
    font-size: 0.9rem;
}
.greenbar {
    background: #02c55d;
    color: #FFF;
    font-size: 1.2rem;
}
.graybar {
    background: #303036;
    color: #FFF;
    font-size: 1.2rem;
}
.graybar .smaller-text {
    font-size: 0.7rem;
}
.footer {
    font-size: 0.8rem;
}
@media (max-width: 414px) {
    .graybar, .greenbar {
        font-size: 1rem;
    } 
}

.ais-Hits-item, .ais-InfiniteHits-item {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.ais-Hits-item .resultMeta {
  color: #303036;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.ais-SearchBox-input:focus {
    border-color: #333;
}

@media (min-width: 768px) {
  .ais-Hits-item, .ais-InfiniteHits-item {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .ais-Hits-item, .ais-InfiniteHits-item {
    width: 25%;
  }
}
